<template>
  <div class="login_container">
    <div class="FormBox bbox">
      <el-form
        ref="regFormRef"
        :model="regForm"
        :rules="regFormRules"
        label-width="0"
        class="reg_form"
      >
        <div class="mt30 mb30 flex align-center justify-between">
          <span class="text333 f20 fw700">{{ $t("reg.title") }}</span>
          <LangSelect></LangSelect>
        </div>
        <el-form-item prop="name">
          <el-input
            v-model="regForm.name"
            clearable
            :placeholder="$t('reg.username')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="age">
          <el-input v-model="regForm.age"
                    clearable
                    :placeholder="$t('reg.age')"></el-input>
        </el-form-item> -->
        <el-form-item prop="phone">
          <el-input
            v-model="regForm.phone"
            clearable
            :placeholder="$t('reg.phone')"
          ></el-input>
        </el-form-item>
        <el-form-item prop="authCode">
          <el-input
            v-model="regForm.authCode"
            :placeholder="$t('reg.authCode')"
            clearable
          >
            <span
              class="pointer textblue positiona handleCode"
              slot="suffix"
              @click="handleCodeClick"
              :disabled="isDisabled"
              >{{ isDisabled ? countText.count + $t("lazyFetch") : $t("reg.getCode") }}
            </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="companyName">
          <el-input
            v-model="regForm.companyName"
            clearable
            :placeholder="$t('reg.comName')"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item prop="address">
          <el-input v-model="regForm.address"
                    clearable
                    :placeholder="$t('reg.comAddr')"></el-input>
        </el-form-item> -->
        <!-- <el-form-item prop="postion">
          <el-input v-model="regForm.postion"
                    clearable
                    :placeholder="$t('reg.position')"></el-input>
        </el-form-item>
        <el-form-item prop="experYears">
          <el-input v-model="regForm.experYears"
                    clearable
                    :placeholder="$t('reg.experYears')"></el-input>
        </el-form-item> -->
        <el-form-item prop="email">
          <el-input
            v-model="regForm.email"
            clearable
            :placeholder="$t('reg.email')"
          ></el-input>
        </el-form-item>

        <el-form-item class="btns mt25">
          <el-button type="primary" @click="reg" class="fw700 f16">{{
            $t("reg.title")
          }}</el-button>
        </el-form-item>
        <!-- 用户协议 -->
        <div class="agreeMents textc mt25">
          <div class="text999">
            <el-checkbox v-model="checked"></el-checkbox>
            <span class="pl5">{{ $t("reg.agree") }}</span>
            <span class="textblue pointer" @click="toReg()">
              {{ $t("reg.explain") }}</span
            >
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import LangSelect from "@/components/LangSelect";
import { Toast } from "vant";
var tips1 = "提示";
var tips2 = "手机号不能为空";
var tips3 = "手机号格式错误";
var tips4 = "请输入合法邮箱";
var tips5 = "请输入用户名";
var tips6 = "请输入公司名称";
var tips7 = "发送成功！";
var tips8 = "注册成功，请耐心等待审核员审核！";
var tips9 = "获取验证码";
var tips10 = "注册失败";
var tips12 = "请先点击阅读然后勾选同意用户协议・用户隐私";
var tips13 = "请勾选同意用户协议・用户隐私";
var tips14 = "请点击阅读用户协议・用户隐私";
export default {
  components: { LangSelect },
  data() {
    const TIME_COUNT = 60;
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error(tips2));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error(tips3));
      } else {
        callback();
      }
    };
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/;
      if (regEmail.test(value)) {
        return callback();
      }
      callback(new Error(tips4));
    };
    return {
      regForm: {
        name: "", // 姓名
        age: "", // 年龄
        phone: "", // 联系方式
        companyName: "", // 公司名称
        address: "", // 公司地址
        postion: "", // 职位
        experYears: "", //经验年数
        email: "", //邮箱
        authCode: "", //验证码
      },
      // 表单验证
      regFormRules: {
        name: [{ required: true, message: tips5, trigger: "blur" }],
        phone: [{ required: true, validator: validatorPhone, trigger: "blur" }],
        companyName: [{ required: true, message: tips6, trigger: "blur" }],
        email: [{ required: true, validator: checkEmail, trigger: "blur" }],
      },
      checked: false,
      seeFlag: false,
      //短信验证码倒计时
      countText: {
        count: "59",
        click: tips9,
      },
      isDisabled: false,
      timer: null,
    };
  },
  methods: {
    // 倒计时
    countTime() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.countText.count = TIME_COUNT;
        this.isDisabled = true;
        this.timer = setInterval(() => {
          if (this.countText.count > 0 && this.countText.count <= TIME_COUNT) {
            this.countText.count--;
          } else {
            this.isDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    handleCodeClick() {
      if (this.regForm.phone === "") {
        this.$notify.error({
          title: tips1,
          message: tips2,
          showClose: true,
        });
      } else if (!/^1\d{10}$/.test(this.regForm.phone)) {
        this.$notify.error({
          title: tips1,
          message: tips3,
          showClose: true,
        });
      } else {
        var sendData = {};
        sendData.phone = this.regForm.phone;
        sendData.type = "regist";
        var that = this;
        this.$http.post("authCode/sendMsg", sendData).then(function (res) {
          this.timer = 60;
          that.countTime();
          if (200 == res.data.code) {
            that.$notify.success({
              title: tips1,
              message: tips7,
              showClose: true,
            });
          }
        });
      }
    },
    reg() {
      this.$refs.regFormRef.validate(async (valid) => {
        if (valid) {
          if (!this.seeFlag && !this.checked) {
            Toast(tips12);
            return;
          }
          if (!this.checked && this.seeFlag) {
            Toast(tips13);
            return;
          }
          if (!this.seeFlag && this.checked) {
            Toast(tips14);
            return;
          }
          const { data: res } = await this.$http.post(
            "member/submitMemberInfo",
            this.regForm
          );
          if (res.code == 200) {
            this.$notify.success({
              title: tips1,
              message: tips8,
              showClose: true,
            });
            this.$router.push("/login");
          } else {
            this.$notify.error({
              title: tips1,
              message: tips10 + ":" + res.message,
              showClose: true,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toReg() {
      var sendData = {};
      sendData.name = this.regForm.name;
      sendData.phone = this.regForm.phone;
      sendData.companyName = this.regForm.companyName;
      sendData.authCode = this.regForm.authCode;
      sendData.email = this.regForm.email;
      sendData.checked = this.checked;
      this.$router.push({
        path: "/regInstructions",
        query: { sendData: sendData },
      });
    },
    changeTips() {
      if (this.$store.getters.language == "zh") {
        tips1 = "提示";
        tips2 = "手机号不能为空";
        tips3 = "手机号格式错误";
        tips4 = "请输入合法邮箱";
        tips5 = "请输入用户名";
        tips6 = "请输入公司名称";
        tips7 = "发送成功！";
        tips8 = "注册成功，请耐心等待审核员审核！";
        tips9 = "获取验证码";
        tips10 = "注册失败";
        tips12 = "请先点击阅读然后勾选同意用户协议・用户隐私";
        tips13 = "请勾选同意用户协议・用户隐私";
        tips14 = "请点击阅读用户协议・用户隐私";
      }
      if (this.$store.getters.language == "en") {
        tips1 = "Prompt";
        tips2 = "The phone number cannot be blank";
        tips3 = "The format of mobile phone number is wrong";
        tips4 = "Please enter a valid email address";
        tips5 = "Please enter a user name";
        tips6 = "Please enter company name";
        tips7 = "Sent successfully!";
        tips8 =
          "Registration is successful, please wait patiently for the auditor to review!";
        tips9 = "Get code";
        tips10 = "Registration failed";
        tips12 =
          'Please click Read and check the "Agree to User Agreement · User Privacy" box';
        tips13 = "Please check Agree to User Agreement · User Privacy";
        tips14 = "Please click here to read User Agreement · User Privacy";
      }
      if (this.$store.getters.language == "ja") {
        tips1 = "提示";
        tips2 = "携帯電話番号を空にしてはいけません";
        tips3 = "携帯番号のフォーマットが間違っています";
        tips4 = "合法的なメールアドレスを入力してください";
        tips5 = "ユーザー名を入力してください";
        tips6 = "会社名を入力してください";
        tips7 = "送信成功!";
        tips8 = "登録に成功して、辛抱強く審査員の審査を待ってください!";
        tips9 = "captchaの取得";
        tips10 = "登録に失敗する";
        tips12 =
          "「お読み」をクリックし、「ユーザー契約・ユーザープライバシーに同意」のチェックを入れてください";
        tips13 =
          "ユーザー契約・ユーザープライバシーに同意するにチェックを入れてください";
        tips14 =
          "ユーザー規約・ユーザープライバシーを読むをクリックしてください";
      }
    },
  },
  created() {
    this.changeTips();
    this.seeFlag = this.$store.getters.seeFlag;
    console.log(this.$route.query.sendData);
    var Data = this.$route.query.sendData;
    if (Data != undefined) {
      this.regForm.name = Data.name;
      this.regForm.authCode = Data.authCode;
      this.regForm.companyName = Data.companyName;
      this.regForm.email = Data.email;
      this.regForm.phone = Data.phone;
      this.checked = Data.checked;
    }
  },
  mounted() {
    this.changeTips();
  },
  updated() {
    this.changeTips();
  },
};
</script>

<style scoped>
.login_container {
  height: 100vh;
  width: 100%;
}
.FormBox {
  padding: 30px;
}

.btns .el-button {
  display: block;
  width: calc(100vw - 60px);
}
.handleCode {
  top: 9px;
  right: 0;
  width: 80px;
}
</style>
